import React, { useState } from 'react';

interface IFAQItemProps {
  icon?: string
  title: string;
  className?: string;
  children: React.ReactNode;
}

export const FAQItem: React.FC<IFAQItemProps> = ({ title, children, className, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`border-b ${className}`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left flex justify-between items-center px-[30px]"
      >
        <span className="text-[20px] themes-text-gray-v9 py-[15px]">
          {icon &&  <i className={`${icon} pr-2 themes-text-brand-v5 `} />}
          {title}
        </span>
        <i
          className={`ri-arrow-down-s-line text-[28px] themes-text-gray-v9 transform transition-transform ${isOpen ? 'rotate-180' : ''
            }`}
        />
      </button>
      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out ${isOpen ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
          }`}
      >
        <div className="pt-2 text-[18px] themes-bg-gray-v1">{children}</div>
      </div>
    </div>
  );
};