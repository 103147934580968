import { IRoute } from 'common/common.interface'
import MainPage from 'pages/main-page/main-page.component'
// import Settings from 'pages/settings/settings.component'

export const loglinesRoutes: IRoute[] = [
    {
        path: '/',
        text: 'MainPage',
        Content: MainPage,
        show: true,
        icon: 'ri-dashboard-line',
    },
    // {
    //     path: '/quotes',
    //     text: 'Quotes',
    //     Content: Quotes,
    //     show: true,
    //     icon: 'ri-file-paper-line',
    // },
    // {
    //     path: '/quote-details',
    //     text: 'Detail Quote',
    //     Content: () => newQuote({ isDetailPage: true }),
    // },
    // {
    //     path: 'forwarding',
    //     text: 'Forwarding',
    //     show: true,
    //     icon: 'ri-truck-line',
    //     expandable: true,
    //     sub: [
    //         {
    //             path: '/bookings',
    //             text: 'Bookings',
    //             Content: Bookings,
    //             parentId: '4',
    //             show: true,
    //         },
    //         {
    //             path: '/shipments',
    //             text: 'Shipments',
    //             Content: Shipments,
    //             show: true,
    //         },
    //     ],
    // },
    // {
    //     path: 'organisation-detail',
    //     text: 'Organisation Details',
    //     Content: OrganisationDetail,
    // },
    // {
    //     path: '/manage-users',
    //     text: 'Manage User',
    //     Content: UserAccess,
    //     show: true,
    //     icon: 'ri-user-line',
    // },
    // {
    //     path: '/settings',
    //     text: 'Settings',
    //     Content: Settings,
    //     show: true,
    //     icon: 'ri-settings-line',
    // },
]
