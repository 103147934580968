const useService = () => {
    const start = async (e: React.MouseEvent<HTMLVideoElement>) => {
        let video = e.target as HTMLVideoElement
        video.play()
    }

    const loadMeta = (e: React.SyntheticEvent<HTMLVideoElement>) => {
        let video = e.target as HTMLVideoElement
        video.currentTime = video.duration
        video.playbackRate = 2
    }
    return {
        start,
        loadMeta,
    }
}

export default useService
