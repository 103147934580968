import Content from 'layout/content.component'
import { useEffect, useRef } from 'react'
import { Outlet } from 'react-router'
import AOS from 'aos'
import 'aos/dist/aos.css'

function App(): React.ReactElement {
    const contentRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        AOS.init({
            delay: 300,
            once: false,
            disableMutationObserver: true,
        })
    }, [])

    useEffect(() => {
        const contentElement = contentRef.current
        if (contentElement) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('aos-animate')
                        } else {
                            entry.target.classList.remove('aos-animate')
                        }
                    })
                },
                {
                    root: contentElement,
                    threshold: 0.3,
                },
            )

            document.querySelectorAll('[data-aos]').forEach((element) => {
                observer.observe(element)
            })

            return () => {
                observer.disconnect()
            }
        }
    }, [])

    return (
        <>
            <div className="logistical-layout">
                <div className="logistical-content w-full" ref={contentRef}>
                    <Content>
                        <Outlet />
                    </Content>
                </div>
            </div>
        </>
    )
}

export default App
