/* eslint-disable no-unused-vars */
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { IBuildVersion, IConfig } from './config.interface'
import { useEffect } from 'react'
import {
    currentThemeSelector,
    setClientVersion,
    setConfig,
    setTheme,
} from './config.slice'
import { store } from 'store'
import { ThemeType } from 'common/common.interface'

export const useConfig = () => {
    const dispatch = useDispatch()
    const currentTheme: ThemeType = useSelector(currentThemeSelector)

    // use Effect ---------------------------------------------------------------
    useEffect(() => {
        starting()
    }, [])

    useEffect(() => {
        const theme = currentTheme ?? 'default'
        const body = document.body
        body.removeAttribute('class')
        body.classList.add(theme)
    }, [currentTheme])


    const starting = async () => {
        // get config
        try {
            // get from selector
            axios.get('/config.json').then((data) => {
                const conf = data.data
                const cf: IConfig = {
                    ...conf,
                }
                dispatch(setConfig(cf))

                // Set Theme Color used
                if (!currentTheme) {
                    // Default Settings
                    dispatch(setTheme('bright-sky-blue-themes'))
                }
            })

            // get build version
            axios.get<IBuildVersion>('/build-version.json').then((data) => {
                const buildVersion = data.data
                const clientVersion = buildVersion.clientVersion
                const lastBuild = buildVersion.lastBuild
                console.info(
                    'Loglines' +
                        '\nClient Version: ' +
                        clientVersion +
                        '\nLast Build: ' +
                        lastBuild,
                )
                const nbv: IBuildVersion = {
                    ...buildVersion,
                }
                dispatch(setClientVersion(nbv))
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export function getConfig(): IConfig {
    const state = store.getState()
    return state.config.config
}
