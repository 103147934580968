import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Hero1 from '../../assets/images/background1.png'

export interface IData {
    video?: string
    img?: string
    icon?: string
    title: string
    desc: string
}

const SliderContainer = ({ data }: { data: IData[] }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [touchStartX, setTouchStartX] = useState<number | null>(null)
    const [touchEndX, setTouchEndX] = useState<number | null>(null)
    const { t } = useTranslation()

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length)
    }

    const prevSlide = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + data.length) % data.length,
        )
    }

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide()
        }, 3000) // Interval 3 detik

        return () => clearInterval(interval)
    }, [])

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStartX(e.touches[0].clientX)
    }

    const handleTouchMove = (e: React.TouchEvent) => {
        setTouchEndX(e.touches[0].clientX)
    }

    const handleTouchEnd = () => {
        if (touchStartX && touchEndX) {
            if (touchStartX - touchEndX > 50) {
                nextSlide()
            }
            if (touchStartX - touchEndX < -50) {
                prevSlide()
            }
        }
        setTouchStartX(null)
        setTouchEndX(null)
    }

    return (
        <div className="relative h-full bg-[#005271]">
            <img
                src={Hero1}
                alt="bg-hero"
                className="absolute inset-0 w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-[#005271] opacity-[80%]"></div>
            <div className="relative z-10 w-full flex justify-center py-[100px] sm:py-16 overflow-hidden md:px-10 lg:px-[6.25rem] sm:px-10 mobile:px-5 ">
                <div className="container text-white ">
                    <div className="inter">
                        <div
                            data-aos="fade-up"
                            className="text-[48px] sm:text-[40px] leading-[50px]"
                        >
                            {t('company_profile.logistics_trust')}
                        </div>
                        <div
                            data-aos="fade-up"
                            className="text-[26px] sm:text-[20px] mb-[50px] font-light leading-[35px]"
                        >
                            {t('company_profile.testimonial_invitation')}
                        </div>
                    </div>
                    <div
                        data-aos="fade-up"
                        className="relative w-full pb-[10px]  rounded-md"
                    >
                        <div
                            className="grid grid-cols-1 w-full overflow-hidden"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                        >
                            <div
                                className="flex transition-transform duration-700"
                                style={{
                                    transform: `translateX(-${currentIndex * 100}%)`,
                                }}
                            >
                                {data.map((data, idx) => {
                                    return (
                                        <div
                                            key={`platform-data-${idx}`}
                                            className="w-full flex-none"
                                        >
                                            <div className="bg-white h-[380px] sm:h-[440px] w-full flex flex-col rounded-md px-10 mobile:px-5 py-10">
                                                <img
                                                    src={data.img}
                                                    alt="bg-hero"
                                                    className="h-auto w-[300px] mb-5"
                                                />
                                                <div className="flex flex-wrap w-full leading-[36px] mobile:leading-[20px]">
                                                    <div className="text-[26px] mobile:text-[20px] themes-text-gray-v6 w-full sm:w-auto tracking-[-1.04px]">
                                                        “{t(data.title)}”
                                                    </div>
                                                    <div className="text-[18px] mobile:text-[16px] font-semibold mt-4 themes-text-gray-v7 w-full sm:w-auto">
                                                        {t(data.desc)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <button
                            onClick={prevSlide}
                            className="absolute sm:hidden md:hidden lg:block left-[-80px] top-1/2 transform -translate-y-1/2 themes-text-brand-v1 px-4 py-2 rounded-full"
                        >
                            <span className="absolute inset-0 bg-current opacity-25 rounded-full"></span>
                            <i className="ri-arrow-left-s-line text-[28px]" />
                        </button>
                        <button
                            onClick={nextSlide}
                            className="absolute sm:hidden md:hidden lg:block right-[-80px] top-1/2 transform -translate-y-1/2  themes-text-brand-v1 px-4 py-2 rounded-full"
                        >
                            <span className="absolute inset-0 bg-current opacity-25 rounded-full"></span>
                            <i className="ri-arrow-right-s-line text-[28px]" />
                        </button>
                        <div className="justify-center mt-4 md:flex sm:flex  lg:hidden">
                            {data.map((_, idx) => (
                                <div
                                    key={idx}
                                    className={`w-3 h-3 rounded-full mx-1 ${currentIndex === idx ? 'themes-bg-brand-v5' : 'themes-bg-brand-v2'}`}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SliderContainer
