import React from 'react'
import './layout.style.css'
import Dropdown from '@components/dropdown/dropdown.component'
import { IDropdownItem } from '@components/dropdown/dropdown.interface'
import { useTranslation } from 'react-i18next'

const flagLabel = (name: string, code: string) => {
    return (
        <div className="flex flex-row flag-label">
            <div className="w-[28px] h-[20px] rounded mobile:mr-[2px] border-[1px] border-gray-200 overflow-hidden">
                <img
                    src={`https://flagcdn.com/${code}.svg`}
                    className=" object-cover object-center w-full h-full"
                    alt={code}
                />
            </div>
            <span className=" mobile:!text-[12px] ml-2">{name}</span>
        </div>
    )
}

const languageDropdown: IDropdownItem[] = [
    {
        label: flagLabel('AUSTRALIA', 'au'),
        value: 'en',
    },
    {
        label: flagLabel('JAPAN', 'jp'),
        value: 'ja',
    },
    {
        label: flagLabel('INDONESIA', 'id'),
        value: 'id',
    },
    {
        label: flagLabel('CHINA', 'cn'),
        value: 'zh',
    },
    // { label: 'GERMANY', value: 'de' },
    // { label: 'SPANISH', value: 'es' },
    // { label: 'FRENCH', value: 'fr' },
    // { label: 'POLAND', value: 'pl' },
]

export default function Header(): React.ReactElement {
    const { i18n } = useTranslation()
    const currentLanguage = i18n.language
    const selectedLanguageItem =
        languageDropdown.find((d) => d.value === currentLanguage) ??
        languageDropdown[0]
    return (
        <div
            className={`sm:w-screen w-full absolute z-50 flex justify-center lg:px-[6.25rem] md:px-10 sm:px-10 mobile:pl-5 pt-3 bg-transparent`}
        >
            <div className="h-[4rem] container overflow-hidden flex items-center justify-between">
                <div className="flex items-center justify-between w-full">
                    <div>
                        <i
                            className={`icon-Logos_LogLines text-[50px] text-white`}
                        />
                    </div>
                    <div className="flex items-center gap-3 mx-2">
                        <Dropdown
                            className="w-[200px] mobile:w-[160px]"
                            options={languageDropdown}
                            defaultValue={[selectedLanguageItem]}
                            onClick={(val) => {
                                const _value = val as IDropdownItem | undefined
                                i18n.changeLanguage(_value?.value as string)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
