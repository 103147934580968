import { useState } from 'react'
import CorePlatformVideo from '@assets/video/LOGDemo24_CRM.mp4'
import APIVideo from '@assets/video/API motion.mp4'
import 'aos/dist/aos.css'
import { FAQItem } from 'container/faq-item/faq-item.component'
import { useTranslation } from 'react-i18next'

export interface IData {
    video?: string
    img?: string
    icon?: string
    title: string
    desc: string
}

const PlatformContainer = ({ data }: { data: IData[] }) => {
    const { t } = useTranslation()

    const [isTab, setIsTab] = useState('company_profile.core_platform')
    const dataPlatform = [
        {
            name: 'company_profile.core_platform',
            icon: 'icon-CorePlatformIcon',
            image: CorePlatformVideo,
            item: [
                {
                    title: 'company_profile.customer_focused',
                    desc: 'company_profile.customer_focused_description',
                },
                {
                    title: 'company_profile.one_platform',
                    desc: 'company_profile.one_platform_description',
                },
                {
                    title: 'company_profile.expertise',
                    desc: 'company_profile.expertise_description',
                },

                {
                    title: 'company_profile.smooth_experience',
                    desc: 'company_profile.user_friendly_portal',
                },
            ],
        },
        {
            name: 'company_profile.api',
            icon: 'icon-APIIcon',
            image: APIVideo,
            item: [
                {
                    title: 'company_profile.improved_customer_experience',
                    desc: 'company_profile.seamless_apis',
                },
                {
                    title: 'company_profile.cost_savings',
                    desc: 'company_profile.api_savings',
                },
                {
                    title: 'company_profile.partnership_opportunities',
                    desc: 'company_profile.api_collaborations',
                },
                {
                    title: 'company_profile.competitive_advantage',
                    desc: 'company_profile.api_advantage',
                },
            ],
        },
    ]
    return (
        <>
            <div className="relative w-full flex justify-center bg-gradient-to-r from-[#014F6C] via-[#006A91] to-[#004D6A] text-white sm:py-12 pt-[80px] pb-[160px] md:px-10 sm:px-10 mobile:px-5  lg:px-[6.25rem]">
                <div className="container lg:px-5">
                    <div
                        data-aos="fade-up"
                        className="text-[48px] sm:text-[48px] mb-3 inter leading-[65px] pb-5 tracking-[-3px]"
                    >
                        {' '}
                        {t('company_profile.all_in_one_platform')}
                    </div>
                    <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2">
                        {data.map((data, idx) => {
                            return (
                                <div
                                    data-aos="fade-up"
                                    key={`platform-data-${idx}`}
                                    className="w-full max-w-[236.67px] pr-3 inter !border-l-[1px] border-white pl-2 mb-10"
                                >
                                    <div className="text-[25px] font-semibold tracking-[-1px]">
                                        {t(data.title)}{' '}
                                    </div>
                                    <div className="text-[14px]  mt-2">
                                        {t(data.desc)}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="flex z-50 justify-center mt-[-75px] pb-[80px] bg-[#F0FAFE] lg:px-[6.25rem] md:px-10 sm:px-10 mobile:px-5 ">
                <div className="relative w-full container themes-text-gray-v9 md:hidden sm:hidden lg:block shadow-sm border-2 rounded-md bg-[#F0FAFE]">
                    <div className="z-50 pt-[3.5px] w-[-webkit-fill-available] absolute grid grid-cols-2 tab-item">
                        <div
                            onClick={() =>
                                setIsTab('company_profile.core_platform')
                            }
                            className={`transition-all duration-300 ${isTab === 'company_profile.core_platform' ? 'bg-white border-b-4 themes-border-brand-v5 ' : 'themes-bg-gray-v2 themes-text-gray-v6 border-b-4 themes-border-gray'} text-[20px] font-semibold p-5 rounded-tl-md cursor-pointer`}
                        >
                            <i
                                className={`icon-CorePlatformIcon pr-2 ${isTab === 'company_profile.core_platform' && 'themes-text-brand-v5'} font-semibold tracking-[0.4px]`}
                            />
                            {t('company_profile.core_platform')}
                        </div>
                        <div
                            onClick={() => setIsTab('company_profile.api')}
                            className={`transition-all duration-300 ${isTab === 'company_profile.api' ? 'bg-white border-b-4 themes-border-brand-v5 ' : 'themes-bg-gray-v2 themes-text-gray-v6 border-b-4 themes-border-gray'}  text-[20px] font-semibold p-5 rounded-tr-md cursor-pointer`}
                        >
                            <i
                                className={`icon-APIIcon pr-2 ${isTab === 'company_profile.api' && 'themes-text-brand-v5'}  font-semibold tracking-[-0.4px]`}
                            />
                            {t('company_profile.api')}
                        </div>
                    </div>
                    {dataPlatform.map((data, idx) => {
                        return (
                            data.name === isTab && (
                                <div
                                    key={`item-data-tabs-${idx}`}
                                    className="pt-16"
                                >
                                    <div className="bg-white grid grid-cols-2 items-center gap-5 flex-wrap px-10 py-5 rounded-b-md ">
                                        <div>
                                            {data.item.map((item, id) => {
                                                return (
                                                    <div
                                                        key={`data-item-${id}`}
                                                        className="py-2"
                                                    >
                                                        <div className="text-[18px] leading-[20px] opacity-[93%] tracking-[-0.8px]">
                                                            <span className=" text-size-L font-bold">
                                                                {t(item.title)}:
                                                            </span>{' '}
                                                            {t(item.desc)}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div>
                                            <div className="rounded-[30px] bg-white">
                                                <video
                                                    src={data.image}
                                                    className="h-full w-full rounded-[25px]"
                                                    autoPlay
                                                    loop
                                                    muted
                                                    playsInline
                                                />
                                            </div>
                                            {/* <img src={data.image} alt="bg-hero" className={`h-auto w-[1920px] rounded-md`} /> */}
                                        </div>
                                    </div>
                                </div>
                            )
                        )
                    })}
                </div>

                <div className="container themes-text-gray-v7 md:grid sm:grid lg:hidden mt-5 lg:px-5 ">
                    {dataPlatform.map((data, idx) => {
                        return (
                            <div key={`item-data-tabs-${idx}`}>
                                <FAQItem
                                    icon={data.icon}
                                    className="bg-white drop-shadow-2xl my-2 rounded-md relative z-50"
                                    title={t(data.name)}
                                >
                                    <div className="col-span-2 grid grid-cols-1 bg-[#E1E9F5] p-5">
                                        <div className="flex justify-center">
                                            <div className="p-3 rounded-md">
                                                <video
                                                    src={data.image}
                                                    className="h-full w-full rounded-md"
                                                    autoPlay
                                                    loop
                                                    muted
                                                    playsInline
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-8">
                                            {data.item.map((item, id) => {
                                                return (
                                                    <div
                                                        key={`data-item-${id}`}
                                                        className="py-2"
                                                    >
                                                        <div className="text-[20px] font-semibold">
                                                            {t(item.title)}{' '}
                                                        </div>
                                                        <div className="text-[18px] themes-text-gray-v7">
                                                            {t(item.desc)}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </FAQItem>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default PlatformContainer
